<!--PRODUCT COMPONENT USED TO VISUALIZE DETAILS : IMAGE, TITLE-->
<template>
  <v-card v-on:click="$router.push({name:'GeneralProductsOne', params:{
                      id : product.id
                    }})">
    <v-img
      class="white--text align-end"
      :src="product.image_path"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px"
      contain
    >
      <v-card-title v-text="product.label"></v-card-title>
      <v-card-text> {{product.reference}}</v-card-text>
    </v-img>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon color="orange lighten-2">mdi-eye</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ProductComponentVisualize',
  props: {
    product: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
