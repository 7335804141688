<template>
  <v-card style="height:fit-content" :disabled="disabled">
    <div>
      <v-checkbox class="select_checkbox"
                  color="success"
                  hide-details
                  :value="item.id"
                  v-model="selected"
                  @click="updateSelected"
      ></v-checkbox>
      <v-img
        class="white--text align-end"
        :src="item.image_path"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
        contain
      >
      </v-img>
    </div>
    <v-card-title style="cursor: pointer;" @click="$router.push({name:'GeneralProductsOne', params:{id : item.id}})">{{ item.label }}</v-card-title>
    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
        <p> Reference : {{ item.reference }}</p>
      </v-row>
      <div class="my-4 subtitle-1">
        Prix : {{ helpers.toEuro(item.price_taxless) }}
      </div>
      <div class="h2" v-if="!_.isEmpty(item.description_short)"> {{ item.description_short }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'ProductComponentWithCheckbox',
  data: () => ({
    selected: null,
    disabled: false
  }),
  props: {
    item: {
      required: true
    },
    additive_from_parent: null, // array of product ids that are additive
    selected_from_parent: null // array of all items selected or not from parent
  },
  methods: {
    // EVENTS :

    /**
     * Event name : 'selected'
     * Payload: Type of operation : 'added' or 'removed' &&  Item involved : item.id
     *
     * */
    updateSelected () {
      if (this.selected) {
        this.$emit('selected', { type: 'added', item: this.item.id })
      } else {
        this.$emit('selected', { type: 'removed', item: this.item.id })
      }
    }
  },
  watch: {
    // react to parent select all || unselect all
    selected_from_parent: function (val) {
      if (_.includes(this.selected_from_parent, this.item.id)) {
        this.selected = this.item.id
      } else this.selected = null
    },
    additive_from_parent: function (val) {
      this.disabled = _.includes(this.additive_from_parent, this.item.id)
    }
  },
  mounted () {
    // pre-select items that have already been selected
    if (_.includes(this.selected_from_parent, this.item.id)) {
      this.selected = this.item.id
    } else this.selected = null

    // if product is additive, disable it
    this.disabled = _.includes(this.additive_from_parent, this.item.id)
  }
}
</script>

<style scoped>

.h2 {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 400px;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  -webkit-line-clamp: 3;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  margin: 20px 0;
}

.select_checkbox {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

</style>
