/**
 * Vuetify Vue CLI Preset
 *
 * router/index.js
 *
 * vue-router documentation: https://router.vuejs.org/
 */

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { Auth } from '@/models/Auth'
import Sessions from '../plugins/sessions'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, _, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      name: 'Root',
      redirect: to => {
        if (!Auth.hasCredentials()) {
          return { name: 'Login' }
        } else {
          return { name: 'Home' }
        }
      },
      meta: {
        noFilter: true
      }

    },
    {
      path: '',
      component: () => import('../layouts/Base'),
      children: [
        {
          path: '',
          component: () => import('../layouts/nothing'),
          children: [
            {
              path: '/login',
              name: 'Login',
              component: () => import('../views/Login.vue')
            },
            {
              path: '/password-forgot',
              name: 'PasswordForgot',
              component: () => import('../views/PasswordForgot.vue')
            }
          ]
        },
        {
          path: '',
          component: () => import('../layouts/default'),
          children: [
            {
              path: '/home',
              name: 'Home',
              component: () => import('../views/Home.vue')
            },
            {
              path: '/profile',
              name: 'Profile',
              component: () => import('../views/user/Profile')
            },
            /**
             * GENERAL => CLIENTS
             */
            {
              path: '/general/clients',
              name: 'GeneralClients',
              redirect: { name: 'GeneralClientsList' }
            },
            {
              path: '/general/clients/list',
              name: 'GeneralClientsList',
              component: () => import('../views/general/clients/List.vue')
            },
            {
              path: '/general/clients/add',
              name: 'GeneralClientsAdd',
              component: () => import('../views/general/clients/Add.vue')
            },
            {
              path: '/general/clients/edit/:id',
              name: 'GeneralClientsEdit',
              component: () => import('../views/general/clients/Edit.vue')
            },
            /**
             * GENERAL => USERS
             */
            {
              path: '/general/users',
              name: 'GeneralUsers',
              redirect: { name: 'GeneralUsersList' }
            },
            {
              path: '/general/users/list',
              name: 'GeneralUsersList',
              component: () => import('../views/general/users/List.vue')
            },
            {
              path: '/general/users/edit/:id',
              name: 'GeneralUsersEdit',
              component: () => import('../views/general/users/Edit.vue')
            },
            {
              path: '/admin/users',
              name: 'AdminUsers',
              redirect: { name: 'AdminUsersList' }
            },
            {
              path: '/admin/users/list',
              name: 'AdminUsersList',
              component: () => import('../views/admin/users/List.vue')
            },
            {
              path: '/admin/users/add',
              name: 'AdminUsersAdd',
              component: () => import('../views/admin/users/Add.vue')
            },
            {
              path: '/admin/users/edit/:id',
              name: 'AdminUsersEdit',
              component: () => import('../views/admin/users/Edit.vue')
            },
            /**
             * GENERAL => PRODUCTS
             */
            {
              path: '/general/products',
              name: 'GeneralProducts',
              redirect: { name: 'GeneralProductsList' }
            },
            {
              path: '/general/products/list',
              name: 'GeneralProductsList',
              component: () => import('../views/general/products/products/List.vue')
            },
            {
              path: '/general/products/add/:type',
              name: 'GeneralProductsAdd',
              props: true,
              component: () => import('../views/general/products/products/Add.vue')
            },
            {
              path: '/general/products/one/:id',
              name: 'GeneralProductsOne',
              component: () => import('../views/general/products/products/One.vue')
            },
            {
              path: '/general/products/edit/:id',
              name: 'GeneralProductsEdit',
              component: () => import('../views/general/products/products/Edit.vue')
            },
            {
              path: '/general/products/duplicate/:id',
              name: 'GeneralProductsDuplicate',
              component: () => import('../views/general/products/products/Duplicate.vue')
            },
            /**
             * GENERAL => PRODUCT => PRODUCT FAMILIES
             */
            {
              path: '/general/product/families',
              name: 'GeneralProductFamilies',
              redirect: { name: 'GeneralProductFamiliesList' }
            },
            {
              path: '/general/product/families/list',
              name: 'GeneralProductFamiliesList',
              component: () => import('../views/general/products/families/List')
            },
            /**
             * GENERAL => PRODUCT => PRODUCT TAXES
             */
            {
              path: '/general/product/taxes',
              name: 'GeneralProductTaxes',
              redirect: { name: 'GeneralProductTaxesList' }
            },
            {
              path: '/general/product/taxes/list',
              name: 'GeneralProductTaxesList',
              component: () => import('../views/general/products/taxes/List')
            },
            /**
             * SITE => {SITE ID} => PRODUCTS
             */
            {
              path: '/site/:id/product/list',
              name: 'SiteProductList',
              component: () => import('../views/site/products/List')
            },
            /**
             * SITE => {SITE ID} => CATALOGUE
             */
            {
              path: '/site/:id/catalogue/list',
              name: 'SiteCatalogueList',
              component: () => import('../views/site/catalogue/List')
            },
            {
              path: '/site/:id/catalogue/add',
              name: 'SiteCatalogueAdd',
              component: () => import('../views/site/catalogue/Add')
            },
            {
              path: '/site/:id/catalogue/edit/:catalogue_id',
              name: 'SiteCatalogueEdit',
              component: () => import('../views/site/catalogue/Edit')
            },
            /**
             * SITE => {SITE ID} => CONFIGURATOR
             */
            {
              path: '/site/:id/configurator/list',
              name: 'SiteConfiguratorsList',
              component: () => import('../views/site/configurators/List')
            },
            {
              path: '/site/:id/configurator/edit/:config_id',
              name: 'SiteConfiguratorsEdit',
              component: () => import('../views/site/configurators/Edit')
            },
            {
              path: '/site/:id/configurator/add',
              name: 'SiteConfiguratorsAdd',
              component: () => import('../views/site/configurators/Add')
            },
            {
              path: '/admin/vat',
              name: 'AdminVat',
              redirect: { name: 'AdminVatList' }
            },
            {
              path: '/admin/vat/list',
              name: 'AdminVatList',
              component: () => import('../views/admin/vat/List.vue')
            },
            {
              path: '/admin/setting',
              name: 'AdminSetting',
              redirect: { name: 'AdminSettingList' }
            },
            {
              path: '/admin/setting/list',
              name: 'AdminSettingList',
              component: () => import('../views/admin/setting/List.vue')
            },
            {
              path: '/admin/users/add',
              name: 'AdminUsersAdd',
              component: () => import('../views/admin/users/Add.vue')
            },
            {
              path: '/admin/users/edit/:id',
              name: 'AdminUsersEdit',
              component: () => import('../views/admin/users/Edit.vue')
            }
            // Create a new Vue template for an About page
            // and uncomment this entry to enable the route
            // {
            //   path: 'about',
            //   name: 'About',
            //   component: () => import('../views/About')
            // },
          ]
        }
      ]
    }
  ]
})

// GUARDS
// =============================================
router.beforeEach((to, from, next) => {
  if (to.name === 'Login' && Auth.hasCredentials()) next({ name: 'Home' })
  else if (to.name !== 'Login' && to.name !== 'PasswordForgot' && !Auth.hasCredentials()) next({ name: 'Login' })
  else if (Auth.hasCredentials() && new Date(Auth.getCredentials().expire_at) < new Date()) {
    Sessions.destroy()
    next({ name: 'Login' })
  } else next()
})

export default router
