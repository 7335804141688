import Sessions from '../plugins/sessions'
import Lodash from 'lodash'

class _ {
  /**
   * Get auth credentials (token) from store
   * @returns {Object}
   */
  getCredentials () {
    return Sessions.get('auth_credentials')
  }

  /**
   * Set auth credentials in store
   * @param {Object} credentials
   * @returns {undefined}
   */
  setCredentials (credentials) {
    Sessions.set('auth_credentials', credentials)
  }

  /**
   * Get token session
   * @returns {boolean}
   */
  hasCredentials () {
    try {
      return (
        !Lodash.isUndefined(Sessions.get('auth_credentials')) &&
        !Lodash.isNull(Sessions.get('auth_credentials')) &&
        (
          !Lodash.isUndefined(this.getCredentials().token) ||
          !Lodash.isNull(this.getCredentials().token)
        )
      )
    } catch (e) {
      return false
    }
  }

  /**
   * Get token session
   * @returns {String}
   */
  getToken () {
    return this.getCredentials().token
  }

  /**
   * Make log in of user
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any> | never>}
   * @private
   */
  _login (data) {
    return new Promise((resolve, reject) => {
      return this.axios({
        method: 'post',
        url: '/login',
        data: data
      })
        .then((response) => {
          this.setCredentials(response.data)
          return resolve()
        })
        .catch((e) => {

        })
    })
  }

  /**
   * Make log out of user
   * @returns {Promise<AxiosResponse<any> | never>}
   * @private
   */
  async logout () {
    try {
      await this.axios.get('/logout', {
        headers: {
          token: Sessions.get('auth_credentials').token
        }
      }).then(() => {
        Sessions.destroy()
      })
    } catch (e) {
      Sessions.destroy()
    }
  }

  /* /!**
   * Make request to forgot user password
   * @returns {Promise<AxiosResponse<any> | never>}
   * @private
   *!/
  _password_forgot (mail) {
    return new Promise((resolve, reject) => {
      return Axios({
        method: 'post',
        url: '/password-reset',
        data: {
          mail: mail
        }
      })
        .then((response) => {
          resolve()
        })
        .catch((e) => {
          reject(AxiosErrorHandler('Auth', '_password_forgot', e))
        })
    })
  }

  /!**
   * Make request to change user password
   * @returns {Promise<AxiosResponse<any> | never>}
   * @private
   *!/
  _password_change (password, password_new, password_confirm) {
    return new Promise((resolve, reject) => {
      return Axios({
        method: 'PUT',
        url: '/profile/password-change',
        data: {
          password: password,
          new: password_new,
          confirm: password_confirm
        },
        headers: {
          token: Auth.getToken()
        }
      })
        .then((response) => {
          resolve()
        })
        .catch((e) => {
          reject(AxiosErrorHandler('Auth', '_password_change', e))
        })
    })
  } */
}

export const Auth = new _()
