<template>
  <v-hover v-slot="{ hover }">
  <v-card rounded class="col col-12 col-md-12 mb-2 hover-effect" style="width: 90%" color="grey lighten-5"   :elevation="hover ? 12 : 2">
        <div class="list-grid d-xs-block d-md-flex d-xl-flex d-lg-flex flex-row">
          <v-checkbox class="select_checkbox align-center mt-0"
                      color="green lighten-1"
                      hide-details
                      :disabled="disabled"
                      :value="item.id"
                      v-model="selected"
                      @click="updateSelected"
          ></v-checkbox>
          <v-img style="margin: auto" :src="item.imagePath" height="70" max-width="128" width="128" contain></v-img>
          <v-card-text class="align-self-center flex-grow-1 d-xs-block d-md-flex d-lg-flex d-xl-flex justify-space-between align-center"
                       style="cursor: pointer;" @click="goToProduct">
            <p class="font-weight-medium ma-0" >{{ item.label }}</p>
            <div class="d-flex justify-end ml-3">
              <p class="font-weight-bold ma-0">{{ item.reference }}</p>
            </div>
          </v-card-text>
        </div>
  </v-card>
  </v-hover>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'ProductComponentWithCheckbox',
  data: () => ({
    selected: null,
    disabled: false
  }),
  props: {
    item: {
      required: true
    },
    loading_from_parent: {
      type: Boolean,
      required: false
    },
    additive_from_parent: null, // array of product ids that are additive
    selected_from_parent: null // array of all items selected or not from parent
  },
  methods: {
    // EVENTS :

    /**
     * Listener : 'SiteCatalogueAddProductComponent', 'SiteFamilyProductSelectComponent'
     * Event name : 'selected'
     * Payload: Type of operation : 'added' or 'removed' &&  Item involved : item.id
     *
     * */
    updateSelected () {
      if (this.selected) {
        this.$emit('selected', { type: 'added', item: this.item.id })
      } else {
        this.$emit('selected', { type: 'removed', item: this.item.id })
      }
    },
    goToProduct () {
      if (!this.loading_from_parent) {
        this.$router.push({ name: 'GeneralProductsOne', params: { id: this.item.id } })
      }
    }
  },
  watch: {
    // react to parent select all || unselect all
    selected_from_parent: function (val) {
      if (!this.disabled) {
        if (_.includes(this.selected_from_parent, this.item.id)) {
          this.selected = this.item.id
        } else this.selected = null
      }
    },
    // react to changes of product additive
    additive_from_parent: function (val) {
      this.disabled = _.includes(this.additive_from_parent, this.item.id)
    },
    // react to loading from parent in case product is not additive
    loading_from_parent: function (val, oldVal) {
      if (!_.includes(this.additive_from_parent, this.item.id)) {
        this.disabled = this.loading_from_parent
      }
    }
  },
  mounted () {
    // pre-select items that have already been selected
    if (_.includes(this.selected_from_parent, this.item.id)) {
      this.selected = this.item.id
    } else this.selected = null

    this.disabled = this.loading_from_parent

    // if product is additive, disable it
    if (_.includes(this.additive_from_parent, this.item.id)) {
      this.disabled = true
    } else { this.disabled = this.loading_from_parent }
  }
}
</script>

<style scoped>

.v-card .hover-effect {
  transition: box-shadow .4s ease-in-out;
}

</style>
