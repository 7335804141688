<template>
  <v-hover v-slot="{ hover }">
  <v-card class="d-flex flex-column align-center hover-effect justify-space-around pa-10" :elevation="hover ? 12 : 2">
    <v-card-title class="text-center overline justify-center">
      <img class="mt-4" src="@/assets/box.png" style="height: 70px">
    </v-card-title>
    <v-card-title class="overline">{{family.text}}</v-card-title>
    <v-card-text class="overline text-center">{{family.nb_products}} produits</v-card-text>
    <v-card-actions class="w-full justify-space-around" style="width: 100%">
      <v-checkbox
        v-model="family.is_additive"
        label="Tout importer"
        @click="switchAuto"
      ></v-checkbox>
      <v-btn
        :disabled="family.is_additive"
        @click="openDialog"
        color="success"
        tile
      > <v-icon left>
        mdi-pencil
      </v-icon>
        Affiner</v-btn>
    </v-card-actions>
  </v-card>
  </v-hover>
</template>

<script>

export default {
  props: {
    family: Object
  },
  data () {
    return {
      clear: false
    }
  },
  methods: {
    openDialog () {
      this.$emit('open-dialog', {
        id: this.$props.family.id
      })
    },
    switchAuto () {
      this.$emit('switch-auto')
    }
  }
}
</script>
