import dotenv from 'dotenv'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import _ from 'lodash'
import './plugins'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import packageData from '../package.json'
import axios from 'axios'
import Moment from 'moment'
import helpers from './plugins/helpers'

import wysiwyg from 'vue-wysiwyg-fixed' // config is optional. more below
import 'vue-wysiwyg-fixed/dist/vueWysiwyg.css'
Vue.use(wysiwyg, {})

dotenv.config()
if (process.env.VUE_APP_SENTRY_ENABLE) {
  Sentry.init({
    Vue,
    debug: true,
    release: packageData.name + '@' + packageData.version,
    environment: process.env.NODE_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing()
    ],
    tracingOptions: {
      trackComponents: true
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}

axios.defaults.baseURL = [process.env.VUE_APP_API, process.env.VUE_APP_API_PATH].join('/')

Vue.config.productionTip = false
Vue.prototype._ = _
Vue.prototype.axios = axios
Vue.prototype.Sentry = Sentry
Vue.prototype.moment = Moment
Vue.prototype.helpers = helpers

new Vue({
  router,
  store,
  vuetify,
  helpers,
  wysiwyg,
  render: h => h(App)
}).$mount('#app')
