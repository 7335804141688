<template>
  <v-row style="width: 100%;">
    <v-col cols="12" sm="4" class="pt-8">
      <v-autocomplete
        v-model="selected_tax"
        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.taxes.name')"
        clearable
        dense
        :items="items"
        :item-text="item_text"
        :item-value="item_value"
        hide-no-data
        hide-selected
        @input="updateInput"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="4">
      <v-text-field
        v-model="value_tax"
        :counter="45"
        :rules="rules_amount"
        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.taxes.value')"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="2">
      <v-autocomplete
        v-model="type_tax"
        :items="types"
        item-text="name"
        item-value="value"
        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.taxes.type')"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="1" class="text-right align-self-center">
      <v-btn
        color="success"
        fab
        x-small
        dark
        @click="addLine"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="1" sm="1" class="text-right align-self-center">
      <v-btn
        color="error"
        fab
        x-small
        dark
        @click="removeTax"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    items: {
      required: true
    },
    item_text: {
      required: true
    },
    item_value: {
      required: true
    },
    index: {
      required: true
    },
    rules_amount: {
      required: true
    }
  },
  data: function () {
    return {
      types: [
        {
          value: '%',
          name: '%'
        }, {
          value: '€',
          name: '€'
        }
      ],
      selected_tax: null,
      value_tax: 1,
      type_tax: '€'
    }
  },
  methods: {
    updateInput () {
      this.$emit('input')
    },
    removeTax () {
      this.$emit('tax-line-remove', this.index)
    },
    addLine () {
      this.$emit('add')
    }
  }
}

</script>

<style scoped>

</style>
