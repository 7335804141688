export default class helpers {
  /**
   * Formats number to currency as per FR number format.
   *
   * @param number
   * @return {string}
   */
  static toEuro (number) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number)
  }

  /**
   * Checks if value is unique in array
   * Return true if unique, false otherwise
   * @param value
   * @param array
   * @return {boolean}
   */
  static uniqueInArray (value, array) {
    return array.filter(x => x === value).length <= 1
  }
}
