<template>
  <v-card class="fill-height" style="height:410px; display: flex;
    align-items: center;
    justify-content: center;">
    <template>
      <v-btn
        color="blue"
        fab
        large
        dark
        class="ml-2 add-button"
        @click="this.clickCustom"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
  </v-card>
</template>

<script>

export default {
  name: 'CardAdd',
  methods: {
    clickCustom () {
      this.$emit('clickCustom')
    }
  }
}
</script>

<style scoped>

.h2 {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 400px;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  -webkit-line-clamp: 3;
}

.v-sheet.v-card:not(.v-sheet--outlined){
  margin: 20px 0;
  width: 250px;
  max-width: 250px;
}

.add-button{
  transform: scale(2);
  margin-left: 0 !important;
}

</style>
