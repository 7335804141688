import fr from 'vuetify/src/locale/fr.ts'

export default {
  ...fr,
  global: {
    search: 'Rechercher',
    add: 'Ajouter',
    edit: 'Éditer',
    back: 'Retour',
    created_at: 'Créer le',
    updated_at: 'Modifier le',
    deleted_at: 'Supprimer le',
    information: 'Information',
    informations: 'Informations',
    actions: 'Actions',
    details: 'Détails',
    required: 'Requis',
    max_char: 'Merci de respecter le nombre maximum de caractères autorisé',
    min_char: 'Merci de respecter le nombre minimum de caractères autorisé',
    sex_label: {
      male: 'Monsieur',
      female: 'Madame',
      other: 'Inconnue'
    },
    form: {
      btn_cancel: 'Annuler',
      btn_confirm: 'Confirmer',
      btn_save: 'Enregistrer',
      required_fields: '* Champ(s) obligatoire(s)'
    }
  },
  navbar: {
    title: 'MP Administration',
    links: {
      profile: 'Mon profil',
      logout: 'Déconnexion'
    }
  },
  auth: {
    login: {
      title: 'Connexion',
      subtitle: 'Pour accéder à votre espace Marguerite Project Administration, merci de bien vouloir vous identifier.',
      form: {
        email: {
          label: 'Email'
        },
        password: {
          label: 'Mot de passe'
        },
        remember: {
          label: 'Se souvenir de moi'
        }
      },
      notification: {
        invalid: 'Certains paramètres sont invalides.',
        not_found: 'L\'identifiant ou le mot de passe ne sont pas corrects.',
        unknown: 'Une erreur se produite pendant la tentative de connexion.'
      },
      forgotten: {
        title: 'Mot de passe oublié ?'
      },
      submit: {
        title: 'Me connecter'
      }
    },
    profile: {
      title: 'Mon profil',
      ui: {
        form: {
          title: 'Mon compte',
          subtitle: 'Modifier vos données personnels concernant votre compte utilisateur.',
          lastname: 'Nom',
          firstname: 'Prénom',
          email: 'Email',
          sexes: {
            male: 'Homme',
            female: 'Femme',
            other: 'Autre'
          },
          password: {
            password1: 'Nouveau mot de passe',
            password2: 'Confirmation du mot de passe'
          }
        }
      },
      notification: {
        edited: 'Votre compte à été mis à jour.',
        password_not_match: 'Les mots de passe ne correspondent pas.',
        invalid_parameters: 'Certains paramètres sont invalides.',
        email_exists: 'L\'adresse mail existe déjà.',
        fail: 'Impossible de modifier votre compte.',
        unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative de modification de votre compte.',
        not_found: 'Une erreur inconnue s\'est produite lors de la tentative de récupération des données de votre compte.'

      }
    }
  },
  home: {
    name: 'Accueil'
  },
  general: {
    name: 'Général',
    clients: {
      name: 'Clients',
      _types: {
        public_school: 'Ecole public',
        private_school: 'Ecole privée',
        company: 'Entreprise',
        local_government_authority: 'Collectivité territoriale'
      },
      list: {
        name: 'Clients',
        notification: {
          load: 'Impossible de récupérer la liste des comptes clients.'
        },
        ui: {
          title: 'Retrouvez ci-dessous la liste des comptes clients.',
          widget: {
            'number-of-clients': {
              label: 'Nombre de clients'
            },
            'monthly-acquisition': {
              label: 'Acquisition mensuel'
            }
          },
          datatable: {
            type: 'Type',
            name: 'Nom',
            sites: 'Sites'
          }
        }
      },
      see: {
        ui: {
          type: 'Type',
          accessible_websites: 'Sites internet accèder par le client : ',
          address: 'Adresse'
        }
      },
      add: {
        name: 'Ajout',
        notification: {
          added: 'Le compte client à bien été ajouter',
          fail: 'Impossible d\'ajouter le compte client.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative d\'ajout du compte client.'
        },
        ui: {
          title: 'Ajout d\'un compte client',
          form: {
            fields: {
              type: {
                label: 'Type'
              },
              name: {
                label: 'Nom'
              },
              address: {
                label: 'Adresse'
              },
              postal_code: {
                label: 'Code postal',
                rules: {
                  invalid: 'Le code postal n\'est pas valide.'
                }
              },
              city: {
                label: 'Ville'
              },
              phone: {
                label: 'Téléphone',
                rules: {
                  invalid: 'Le numéro de téléphone n\'est pas valide.'
                }
              },
              email: {
                label: 'E-mail',
                rules: {
                  invalid: 'L\'adresse email n\'est pas valide.'
                }
              }
            }
          },
          default: {
            name: '{ Nom du compte }',
            type: '{ Type }'
          }
        }
      },
      edit: {
        name: 'Edition',
        notification: {
          edited: 'Le compte client à bien été modifié.',
          not_find: 'Le compte client ne semble pas exister.',
          fail: 'Impossible de modifer le compte client.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative de modification du compte client.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la tentative de récupération des données du compte client.'
        },
        ui: {
          title: 'Edition d\'un compte client',
          data: {
            accessible_websites: {
              label: 'Sites internet accèder par le client : '
            }
          },
          form: {
            fields: {
              type: {
                label: 'Type'
              },
              name: {
                label: 'Nom'
              },
              address: {
                label: 'Adresse'
              },
              postal_code: {
                label: 'Code postal',
                rules: {
                  invalid: 'Le code postal n\'est pas valide.'
                }
              },
              city: {
                label: 'Ville'
              },
              phone: {
                label: 'Téléphone',
                rules: {
                  invalid: 'Le numéro de téléphone n\'est pas valide.'
                }
              },
              email: {
                label: 'E-mail',
                rules: {
                  invalid: 'L\'adresse email n\'est pas valide.'
                }
              }
            }
          },
          default: {
            name: '{ Nom du compte }',
            type: '{ Type }'
          }
        }
      },
      delete: {
        notification: {
          not_found: 'Le compte client sélectionné ne semble pas exister.',
          users_exists: 'Le compte client contient des utilisateurs actifs, merci de bien vouloir les supprimer préalablement.',
          fail: 'Impossible de supprimer le compte client.',
          deleted: 'Le compte client à bien été supprimer.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression du compte client.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer ce compte client ?'
        }
      }
    },
    users: {
      name: 'Utilisateurs',
      see: {
        ui: {
          role: 'Fonction',
          client: 'Compte client : ',
          accessible_websites: 'Sites internet accessible à <br>l\'utilisateur client : ',
          address: 'Adresse'
        }
      },
      list: {
        name: 'Utilisateurs',
        notification: {
          load: 'Impossible de récupérer la liste des comptes utilisateur clients.'
        },
        ui: {
          title: 'Utilisateurs client',
          subtitle: 'Retrouvez ci-dessous la liste des comptes utilisateur clients.',
          widget: {
            'number-of-users': {
              label: 'Nombre d\'utilisateurs client'
            },
            'number-of-users-connected-this-month': {
              label: 'Nombre d\'utilisateurs connecté ce mois-ci'
            },
            'monthly-acquisition': {
              label: 'Acquisition mensuel'
            }
          },
          datatable: {
            sex: 'Civilité',
            lastname: 'Nom',
            firstname: 'Prénom',
            role: 'Fonction',
            client: 'Compte client'
          }
        }
      },
      edit: {
        name: 'Edition',
        notification: {
          edited: 'L\'utilisateur client à bien été modifier.',
          not_find: 'L\'utilisateur client ne semble pas exister.',
          fail: 'L\'utilisateur client n\'as pas pu être modifier.',
          not_same_password: 'Les deux mots de passe ne sont pas identique.',
          email_exist: 'L\'adresse email est déjà utilisé par un autre utilisateur.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de l\'utilisateur client.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la récupération des données de l\'utilisateur client.'
        },
        ui: {
          title: 'Edition de l\'utilisateur client',
          data: {
            client: 'Compte client : ',
            accessible_websites: {
              label: 'Sites internet accessible à <br>l\'utilisateur client : '
            }
          },
          form: {
            passwords: {
              divider: 'Changement du mot de passe '
            },
            fields: {
              sex: {
                label: 'Civilité'
              },
              lastname: {
                label: 'Nom'
              },
              firstname: {
                label: 'Prénom'
              },
              role: {
                label: 'Fonction'
              },
              phone: {
                label: 'Téléphone',
                rules: {
                  invalid: 'Le numéro de téléphone n\'est pas valide.'
                }
              },
              email: {
                label: 'E-mail',
                rules: {
                  invalid: 'L\'adresse email n\'est pas valide.'
                }
              },
              password1: {
                label: 'Mot de passe',
                hint: '8 caractères minimum sont requis',
                rules: {
                  min: 'Le mot de passe doit comporter au moins 8 caractères'
                }
              },
              password2: {
                label: 'Confirmation du mot de passe',
                hint: '8 caractères minimum sont requis',
                rules: {
                  min: 'Le mot de passe doit comporter au moins 8 caractères'
                }
              }
            }
          },
          default: {
            name: '{ Nom de l\'utilisateur }',
            role: '{ Fonction }'
          }
        }
      },
      delete: {
        notification: {
          not_found: 'Le compte utilisateur client sélectionné ne semble pas exister.',
          fail: 'Impossible de supprimer le compte utilisateur client.',
          deleted: 'L\'utilisateur client à bien été supprimer.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression du compte utilisateur client.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer cette utilisateur client ?'
        }
      }
    }
  },
  sites: {
    name: 'Sites',
    products: {
      name: 'Produits',
      list: {
        name: 'Produits',
        ui: {
          title: 'Produits',
          subtitle: 'Retrouvez ci-dessous la liste des produits du site.',
          notification: {
            load: 'Impossible de récupérer la liste des produits du site enregistrées sur la plateforme.',
            infinite_loading: {
              no_more: 'Il n\'y a plus de produits du site à afficher',
              no_results: 'Aucun résultat trouvé'
            }
          },
          content: {
            families: {
              name: 'Rajout produit : à partir d\'une famille produit',
              hint: 'Permet de rajouter des produits au site à partir d\'une famille produit. Convient pour un rajout en masse des produits. Avec l\'option tout importer, tout rajout d\'un produit à la famille est automatiquement rajouté au site. ',
              label: 'Familles produit',
              select: {
                add: 'Sélectionner une famille produit'
              },
              family_card: {
                import_all: 'Tout importer',
                products_total: 'produits',
                refine: 'Affiner'
              },
              family_products: {
                search: 'Rechercher',
                select_all: 'Sélectionner tout',
                unselect_all: 'Désélectionner tout',
                no_results: 'Aucun produit trouvé',
                notification: {
                  added: 'Les produits ont été bien sélectionnées.',
                  load: 'Impossible de récupérer la liste des produits.',
                  site_not_found: 'Le site pour lequel vous souhaitez récupérer les données n\'existe pas ou n\'existe plus. Veuillez réessayer.',
                  product_family_not_found: 'La famille produit pour laquelle vous souhaitez récupérer les produits n\'existe pas ou n\'existe plus. Veuillez réessayer.'
                }
              }
            },
            products: {
              name: 'Rajout produit : A partir d\'une liste',
              hint: 'Permet de rajouter des produits au site à partir d\'une liste produits ou d\'une recherche. Convient pour un rajout plus précis, un produit à la fois.',
              label: 'Produits',
              card: {
                add: 'Rajouter',
                see: 'Voir selection produits',
                item: 'produits'
              }
            }
          }
        },
        notification: {
          save: 'La sélection des produits a été bien sauvegardé.',
          fail: 'Une erreur est survenue lors de l\'enregistrement des produits dans le site. Veuillez réessayer.',
          product_family_not_found: 'Certaines familles produits ne sont pas valides. Veuillez réessayer.',
          product_not_found: 'Certains produits ne sont pas valides. Veuillez réessayer.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données du site.'

        }
      }
    },
    catalogue: {
      name: 'Catalogue',
      list: {
        name: 'Catalogue',
        ui: {
          title: 'Catalogue',
          subtitle: 'Retrouvez ci-dessous la liste des catégories du catalogue.',
          datatable: {
            image: 'Image',
            name: 'Nom catégorie',
            count_products: 'Total produits'
          },
          notification: {
            load: 'Impossible de récupérer la liste des catégories du catalogue enregistrées sur la plateforme.'
          }
        }
      },
      add: {
        name: 'Ajout',
        notification: {
          added: 'La catégorie du catalogue a bien été ajouté.',
          exist: 'Une catégorie du catalogue avec le même nom existe déjà.',
          fail: 'La catégorie du catalogue n\'a pas pu être ajoutée. Certains paramètres sont invalides.',
          product_family_not_found: 'Certaines familles produits ne sont pas valides. Veuillez réessayer.',
          product_not_found: 'Certains produits ne sont pas valides. Veuillez réessayer.',
          site_not_found: 'Le site pour lequel vous souhaitez rajouter un catalogue ne semble pas exister. Veuillez réessayer.',
          load_families: 'Une erreur se produite pendant la tentative de récupération des familles produit. Veuillez réessayer.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données du catalogue.'
        },
        ui: {
          title: 'Ajouter une nouvelle catégorie du catalogue',
          ui: {
            form: {
              general: {
                title: 'Informations principales',
                inputs: {
                  name: {
                    name: 'Nom catégorie catalogue',
                    hint: 'Nom de la catégorie du catalogue'
                  },
                  icon: {
                    name: 'Icône catégorie catalogue',
                    hint: 'L\'icône de la catégorie du catalogue. Taille obligatoire de < 2mb.',
                    rules: {
                      size: 'La taille de l\'image ne doit pas dépasser 2 mb.'
                    },
                    button: {
                      hint: 'En panne de créativité? Voir un site avec des icônes.'
                    }
                  }
                },
                description: {
                  title: 'Description',
                  name: 'Description',
                  placeholder: 'Retrouvez tous les produits de la catégorie ',
                  hint: 'La description de la catégorie du catalogue'
                }
              },
              content: {
                families: {
                  name: 'Rajout produit : à partir d\'une famille produit',
                  hint: 'Permet de rajouter des produits au catalogue à partir d\'une famille produit. Convient pour un rajout en masse des produits.',
                  label: 'Familles produit',
                  select: {
                    add: 'Sélectionner une famille produit'
                  },
                  family_card: {
                    import_all: 'Tout importer',
                    products_total: 'produits',
                    refine: 'Affiner'
                  },
                  family_products: {
                    search: 'Rechercher',
                    select_all: 'Sélectionner tout',
                    unselect_all: 'Désélectionner tout',
                    no_results: 'Aucun produit trouvé',
                    notification: {
                      added: 'Les produits ont été bien sélectionnées.',
                      load: 'Impossible de récupérer la liste des produits.',
                      site_not_found: 'Le site pour lequel vous souhaitez récupérer les données n\'existe pas ou n\'existe plus. Veuillez réessayer.',
                      product_family_not_found: 'La famille produit pour laquelle vous souhaitez récupérer les produits n\'existe pas ou n\'existe plus. Veuillez réessayer.'
                    }
                  }
                },
                products: {
                  name: 'Rajout produit : A partir d\'une liste',
                  hint: 'Permet de rajouter des produits au catalogue à partir d\'une liste produits ou d\'une recherche. Convient pour un rajout plus précis, un produit à la fois.',
                  label: 'Produits',
                  card: {
                    add: 'Rajouter',
                    see: 'Voir selection produits',
                    item: 'produits'
                  }
                }
              }
            },
            confirm: {
              no_products: 'Aucun produit sélectionné. '
            }
          }
        }
      },
      one: {
        name: 'Détails',
        ui: {
          families: {
            name: 'Familles produit associées',
            info: 'Rajout automatique au catalogue de tous les produits qui appartient aux familles produits listées.',
            none: 'Aucune famille produit associée à cette catégorie.'
          },
          produits: {
            name: 'Produits dans le catalogue',
            total: 'produits',
            none: 'Aucun produit dans la catégorie.'
          }
        }

      },
      edit: {
        name: 'Edition',
        notification: {
          edited: 'La catégorie du catalogue a bien été modifiée.',
          exist: 'Une catégorie du catalogue avec le même nom existe déjà.',
          fail: 'La catégorie du catalogue n\'a pas pu être modifiée. Certains paramètres sont invalides.',
          product_family_not_found: 'Certaines familles produits ne sont pas valides. Veuillez réessayer.',
          product_not_found: 'Certains produits ne sont pas valides. Veuillez réessayer.',
          catalogue_not_found: 'La catégorie du catalogue que vous souhaitez éditer ne semble pas exister. Veuillez réessayer.',
          site_not_found: 'Le site pour lequel vous souhaitez rajouter un catalogue ne semble pas exister. Veuillez réessayer.',
          load_families: 'Une erreur se produite pendant la tentative de récupération des familles produit. Veuillez réessayer.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données du catalogue.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la tentative de récupération des données du catalogue. Veillez réessayer.'
        },
        ui: {
          title: 'Éditer la catégorie du catalogue',
          ui: {
            form: {
              general: {
                title: 'Informations principales',
                inputs: {
                  name: {
                    name: 'Nom catégorie catalogue',
                    hint: 'Nom de la catégorie du catalogue'
                  },
                  icon: {
                    name: 'Icône catalogue',
                    hint: 'L\'icône de la catégorie du catalogue. Taille obligatoire de < 2mb.',
                    rules: {
                      size: 'La taille de l\'image ne doit pas dépasser 2 mb.'
                    },
                    button: {
                      hint: 'En panne de créativité? Voir un site avec des icônes.'
                    }
                  }
                },
                description: {
                  title: 'Description',
                  name: 'Description',
                  placeholder: 'Retrouvez tous les produits de la catégorie ',
                  hint: 'La description de la catégorie du catalogue'
                }
              },
              content: {
                families: {
                  name: 'Rajout produit : à partir d\'une famille produit',
                  hint: 'Permet de rajouter des produits au catalogue à partir d\'une famille produit. Convient pour un rajout en masse des produits.',
                  label: 'Familles produit',
                  select: {
                    add: 'Sélectionner une famille produit'
                  },
                  family_card: {
                    import_all: 'Tout importer',
                    products_total: 'produits',
                    refine: 'Affiner'
                  },
                  family_products: {
                    search: 'Rechercher',
                    select_all: 'Sélectionner tout',
                    unselect_all: 'Désélectionner tout',
                    no_results: 'Aucun produit trouvé',
                    notification: {
                      added: 'Les produits ont été bien sélectionnées.',
                      load: 'Impossible de récupérer la liste des produits.',
                      site_not_found: 'Le site pour lequel vous souhaitez récupérer les données n\'existe pas ou n\'existe plus. Veuillez réessayer.',
                      product_family_not_found: 'La famille produit pour laquelle vous souhaitez récupérer les produits n\'existe pas ou n\'existe plus. Veuillez réessayer.'
                    }
                  }
                },
                products: {
                  name: 'Rajout produit : A partir d\'une liste',
                  hint: 'Permet de rajouter des produits au catalogue à partir d\'une liste produits ou d\'une recherche. Convient pour un rajout plus précis, un produit à la fois.',
                  label: 'Produits',
                  card: {
                    add: 'Rajouter',
                    see: 'Voir selection produits'
                  }
                }
              }
            },
            confirm: {
              no_products: 'Aucun produit sélectionné. '
            }
          }
        }
      },
      delete: {
        notification: {
          site_not_found: 'Le site pour lequel vous souhaitez supprimer la catégorie ne semble pas exister. Veuillez réessayer.',
          not_found: 'La catégorie du catalogue que vous souhaitez supprimer ne semble pas exister. Veuillez réessayer.',
          fail: 'Impossible de supprimer la catégorie du catalogue.',
          deleted: 'La catégorie catalogue à bien été supprimée.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer cette catégorie de catalogue ?'
        }
      }
    }
  },
  product: {
    name: 'Produits',
    products: {
      name: 'Produits',
      list: {
        name: 'Produits',
        notification: {
          load: 'Impossible de récupérer la liste des produits enregistrés sur la plateforme.'
        },
        ui: {
          title: 'Produits',
          subtitle: 'Retrouvez ci-dessous la liste des produits.',
          datatable: {
            reference: 'Reference',
            reference_provider: 'Reference fournisseur',
            image: 'Image',
            label: 'Label',
            manufacturer: 'Producteur',
            price_taxless: 'Prix HT',
            is_service: 'Service',
            is_solution: 'Solution'
          }
        }
      },
      add: {
        name: 'Ajout',
        notification: {
          added: 'Le produit a bien été ajouté.',
          exist: 'Un produit avec la même reference existe déjà.',
          fail: 'Le produit n\'a pas pu être ajouté. Certains paramètres sont invalides.',
          similar_product_not_found: 'Certains produits similaires ne sont pas valides',
          extra_product_not_found: 'Certains produits extra ne sont pas valides',
          tax_not_found: 'Certaines taxes ne sont pas valides',
          product_family_not_found: 'Certaines catégories ne sont pas valides',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données du produit.'
        },
        ui: {
          title: 'Ajouter un nouveau produit',
          ui: {
            form: {
              general: {
                title: 'Informations principales',
                inputs: {
                  reference: {
                    name: 'Reference interne',
                    hint: 'Reference interne du produit'
                  },
                  reference_provider: {
                    name: 'Reference fournisseur',
                    hint: 'Reference du fournisseur'
                  },
                  reference_constructor: {
                    name: 'Reference constructeur',
                    hint: 'Reference du constructeur'
                  },
                  manufacturer: 'Producteur',
                  ean: {
                    name: 'EAN',
                    hint: 'Code-barres EAN (European Article Numbering)'
                  },
                  weight: 'Poids',
                  rules: {
                    must_be_number: 'La valeur doit être un nombre valide.',
                    must_be_unique: 'La valeur doit être unique.'
                  },
                  image: {
                    name: 'Image principale',
                    hint: 'L\'image principale du produit. Taille obligatoire de < 2mb.',
                    rules: {
                      size: 'La taille de l\'image ne doit pas dépasser 2 mb.'
                    }
                  },
                  images: {
                    name: 'Images supplémentaires',
                    rules: {
                      size: 'Certaines de vos images dépassent la taille max autorisée de 2mb.'
                    }
                  },
                  is_service: {
                    name: 'Service'
                  },
                  is_solution: {
                    name: 'Solution'
                  }
                },
                product: {
                  title: 'Description produit',
                  inputs: {
                    label: {
                      name: 'Titre produit',
                      hint: 'Le nom du produit'
                    },
                    description_short: {
                      name: 'Description courte',
                      hint: 'Mini description du produit (spécifications techniques)'
                    },
                    description: {
                      name: 'Description longue',
                      hint: 'La description longue du produit (photos, videos, utilisation)'
                    }
                  }
                },
                financial: {
                  title: 'Prix et taxes',
                  bundle: {
                    select: 'Rajouter des produits dans le bundle *',
                    title: 'Liste des produits dans le bundle'
                  },
                  inputs: {
                    price_taxless: {
                      name: 'Prix HT'
                    },
                    price_starting: {
                      name: 'Prix \'à partir de\' '
                    },
                    rent_taxless: {
                      name: 'Prix location HT'
                    },
                    rent_months: {
                      name: 'Nb de mois *'
                    },
                    show_monthly_price: {
                      name: 'Afficher prix location'
                    },
                    vat: {
                      name: 'TVA applicable *'
                    },
                    taxes: {
                      name: 'Taxe applicable',
                      value: 'Montant taxe',
                      type: 'Type'
                    }
                  }
                }
              },
              extra: {
                characteristics: {
                  button: 'Ajouter une nouvelle caractéristique',
                  title: 'Caractéristiques',
                  select: 'Caractéristique produit',
                  input: 'Valeur'
                },
                families: {
                  name: 'Catégories produit associées',
                  button: 'Ajouter une nouvelle famille produit',
                  label: 'Catégories'
                },
                similar: {
                  name: 'Produits similaires',
                  label: 'Produits'
                },
                extra: {
                  name: 'Produits extra',
                  label: 'Produits'
                },
                bundle: {
                  title: 'Produits dans le bundle',
                  datatable: {
                    reference: 'Reference interne',
                    label: 'Titre',
                    quantity: 'Quantité',
                    price_taxless: 'Prix HT',
                    order: 'Order'
                  }
                },
                images: 'Images produits supplémentaires',
                files: {
                  files: 'Fichiers',
                  file_type: 'Type de fichier *',
                  file: 'Fichier',
                  rules: {
                    size: 'La taille du fichier ne doit pas dépasser 2 mb.'
                  }
                }
              }
            }
          }
        }
      },
      see: {
        name: 'Détails',
        ui: {
          intro: {
            reference: 'Reference',
            manufacturer: 'Producteur',
            price: 'Prix HT',
            main: {
              title: 'Informations principales',
              reference: 'Reference',
              reference_constructor: 'Reference constructeur',
              reference_provider: 'Reference fournisseur',
              ean: 'EAN',
              manufacturer: 'Producteur',
              specification: {
                title: 'Spécification',
                is_service: 'Service',
                is_solution: 'Solution'
              }
            },
            financial: {
              title: 'Prix et taxes',
              price: 'Prix HT',
              price_starting: 'Prix \'à partir de\'',
              rent: 'Prix location HT',
              nb_months: 'Nb des mois',
              vat: 'Taux TVA',
              taxes: 'Taxes'
            },
            category: {
              title: 'Catégories',
              none: 'Aucune catégorie renseignée.'
            },
            description: {
              title: 'Description produit',
              none: 'Aucune description renseignée.'
            },
            characteristic: {
              title: 'Caractéristiques',
              none: 'Aucune caractéristique renseignée.'
            },
            more: 'Voir plus'
          },
          files: {
            name: 'Fichiers'
          },
          image: {
            title: 'Image principale'
          },
          similar: {
            title: 'Produits similaires',
            none: 'Aucun produit similaire associé.'
          },
          bundle: {
            title: 'Produits dans le bundle',
            datatable: {
              reference: 'Reference interne',
              label: 'Titre',
              quantity: 'Quantité',
              price_taxless: 'Prix HT'
            }
          },
          extra: {
            title: 'Produits complémentaires',
            none: 'Aucun produit complémentaire associé.'
          }
        },
        notification: {
          not_found: 'Le produit n\'existe pas ou n\'existe plus.',
          unknown_error: 'Une erreur s\'est produite pendant la tentative de récupération des données.'
        }
      },
      edit: {
        name: 'Édition',
        ui: {
          title: 'Édition produit',
          intro: {
            reference: 'Reference',
            manufacturer: 'Producteur',
            price: 'Prix HT',
            main: {
              title: 'Informations principales',
              reference: 'Reference',
              reference_constructor: 'Reference constructeur',
              reference_provider: 'Reference fournisseur',
              ean: 'EAN',
              manufacturer: 'Producteur'
            },
            financial: {
              title: 'Prix et taxes',
              price: 'Prix HT',
              price_starting: 'Prix \'à partir de\'',
              rent: 'Prix location HT',
              nb_months: 'Nb des mois',
              show_monthly_price: {
                name: 'Afficher prix location'
              },
              vat: 'Taux TVA',
              taxes: {
                title: 'Taxes',
                existing: 'Taxes existantes:',
                new: 'Rajouter une nouvelle taxe',
                form: {
                  name: 'Taxe applicable',
                  value: 'Montant taxe',
                  type: 'Type'
                }
              }
            },
            category: {
              title: 'Catégories',
              none: 'Aucune catégorie renseignée.'
            },
            characteristic: {
              title: 'Caractéristiques',
              none: 'Aucune caractéristique renseignée.'
            },
            more: 'Voir plus'
          },
          files: {
            name: 'Fichiers',
            existing: 'Fichiers existants:',
            new: 'Rajouter un nouveau fichier'
          },
          characteristics: {
            title: 'Caractéristiques',
            existing: 'Caractéristiques existantes:',
            new: 'Rajouter une nouvelle caractéristique'
          },
          image: {
            title: 'Image principale',
            label: 'Remplacer image principale',
            hint: 'Taille obligatoire de < 2 MB. Nom du fichier sans espaces et sans caractères spéciaux.'

          },
          images: {
            title: 'Images supplémentaires',
            hint: 'Taille obligatoire de < 2 MB par fichier. Nom du fichier sans espaces et sans caractères spéciaux.'
          },
          is_service: {
            title: 'Service'
          },
          is_solution: {
            title: 'Solution'
          },
          similar: {
            title: 'Produits similaires',
            none: 'Aucun produit similaire associé.'
          },
          bundle: {
            switch: 'Produit bundle',
            title: 'Produits dans le bundle',
            datatable: {
              reference: 'Reference interne',
              label: 'Titre',
              quantity: 'Quantité',
              price_taxless: 'Prix HT',
              order: 'Ordre'
            },
            rules: {
              not_zero: 'La valeur doit être supérieure à 0.',
              must_be_unique: 'La valeur doit être unique.'
            }
          },
          extra: {
            title: 'Produits complémentaires',
            none: 'Aucun produit complémentaire associé.'
          }
        },
        notification: {
          edited: 'Le produit a bien été édité',
          exist: 'Un produit avec la même reference existe déjà.',
          fail: 'Le produit n\'a pas pu être édité. Certains paramètres sont invalides.',
          similar_product_not_found: 'Certains produits similaires ne sont pas valides',
          extra_product_not_found: 'Certains produits extra ne sont pas valides',
          file: 'Certains fichiers ne sont pas valides',
          product_family_not_found: 'Certaines catégories ne sont pas valides',
          not_found: 'Le produit n\'existe pas ou n\'existe plus.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données du produit.',
          unknown_error: 'Une erreur s\'est produite pendant la tentative de récupération des données.'
        }
      },
      delete: {
        notification: {
          not_found: 'Le produit sélectionné ne semble pas exister.',
          fail: 'Impossible de supprimer le produit.',
          used: 'Le produit est utilisée et ne peut pas être supprimé.',
          deleted: 'Le produit à bien été supprimer.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer ce produit ?'
        }
      }
    },
    taxes: {
      name: 'Taxes',
      list: {
        name: 'Taxes produits',
        notification: {
          load: 'Impossible de récupérer la liste des taxes produit enregistrées sur la plateforme.'
        },
        ui: {
          title: 'Taxes produits',
          subtitle: 'Retrouvez ci-dessous la liste des taxes produits.',
          datatable: {
            name: 'Nom',
            value: 'Montant'
          }
        }
      },
      delete: {
        notification: {
          not_found: 'La taxe sélectionnée ne semble pas exister.',
          fail: 'Impossible de supprimer la taxe.',
          used: 'La taxe est utilisée et ne peut pas être supprimée.',
          deleted: 'La taxe à bien été supprimer.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression de la taxe.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer cette taxe ?'
        }
      },
      add: {
        name: 'Ajouter une nouvelle taxe produit',
        notification: {
          added: 'La taxe à bien été ajoutée.',
          exist: 'La taxe existe déjà.',
          fail: 'La taxe n\'a pas pu être ajoutée.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la taxe.'
        },
        ui: {
          form: {
            fields: {
              name: {
                label: 'Nom'
              },
              value: {
                label: 'Valeur',
                rules: {
                  value_number: 'La valeur doit être un nombre valide.',
                  value_not_zero: 'La valeur doit être supérieure à zéro.'
                }
              }
            }
          }
        }
      },
      edit: {
        name: 'Édition taxe produit',
        notification: {
          edited: 'La taxe produit à bien été modifiée.',
          not_find: 'La taxe produit ne semble pas exister.',
          fail: 'La taxe produit n\'a pas pu être modifiée.',
          exist: 'La taxe produit existe déjà.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la taxe produit.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la récupération des données de la taxe produit.'

        },
        ui: {
          form: {
            fields: {
              name: {
                label: 'Nom'
              },
              value: {
                label: 'Valeur',
                rules: {
                  value_number: 'La valeur doit être un nombre valide.',
                  value_not_zero: 'La valeur doit être supérieure à zéro.'
                }
              }
            }
          }
        }
      }
    },
    families: {
      name: 'Familles produit',
      list: {
        name: 'Familles produit',
        notification: {
          load: 'Impossible de récupérer la liste des familles produit enregistrées sur la plateforme.'
        },
        ui: {
          title: 'Familles produits',
          subtitle: 'Retrouvez ci-dessous la liste des familles produits.',
          datatable: {
            name: 'Nom',
            parent: 'Parent (appartient à)'
          }
        }
      },
      delete: {
        notification: {
          not_found: 'La famille sélectionnée ne semble pas exister.',
          fail: 'Impossible de supprimer la famille.',
          used: 'La famille est utilisée et ne peut pas être supprimée.',
          deleted: 'La famille à bien été supprimer.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression de la famille.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer cette famille ?'
        }
      },
      add: {
        name: 'Ajouter une nouvelle famille produit',
        notification: {
          added: 'La famille à bien été ajoutée.',
          exist: 'La famille existe déjà.',
          fail: 'La famille n\'a pas pu être ajoutée.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la famille.'
        },
        ui: {
          form: {
            fields: {
              value: {
                label: 'Nom'
              },
              parent: {
                label: 'Catégorie parent'
              }
            }
          }
        }
      },
      edit: {
        name: 'Édition famille produit',
        notification: {
          edited: 'La famille produit à bien été modifiée.',
          not_find: 'La famille produit ne semble pas exister.',
          fail: 'La famille produit n\'a pas pu être modifiée.',
          exist: 'La famille produit existe déjà.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la famille produit.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la récupération des données de la famille produit.'

        },
        ui: {
          form: {
            fields: {
              value: {
                label: 'Nom'
              },
              parent: {
                label: 'Catégorie parent'
              }
            }
          }
        }
      }
    },
    characteristics: {
      name: 'Caractéristiques',
      list: {
        name: 'Caractéristiques',
        notification: {
          load: 'Impossible de récupérer la liste des caractéristiques produits enregistrées sur la plateforme.'
        },
        ui: {
          title: 'Caractéristiques produits',
          subtitle: 'Retrouvez ci-dessous la liste des caractéristiques produits.',
          datatable: {
            name: 'Nom',
            label: 'Label'
          }
        }
      },
      delete: {
        notification: {
          not_found: 'La caractéristique sélectionnée ne semble pas exister.',
          fail: 'Impossible de supprimer la caractéristique.',
          used: 'La caractéristique est utilisée et ne peut pas être supprimée.',
          deleted: 'La caractéristique à bien été supprimer.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression de la caractéristique.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer cette caractéristique ?'
        }
      },
      add: {
        name: 'Ajouter une nouvelle caractéristique',
        notification: {
          added: 'La caractéristique à bien été ajoutée.',
          exist: 'La caractéristique existe déjà.',
          fail: 'La caractéristique n\'a pas pu être ajoutée.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la caractéristique.'
        },
        ui: {
          form: {
            fields: {
              label: {
                label: 'Label',
                parent: 'Caractéristique parent'
              }
            }
          }
        }
      },
      edit: {
        name: 'Édition caractéristique produit',
        notification: {
          edited: 'La caractéristique à bien été modifiée.',
          not_find: 'La caractéristique ne semble pas exister.',
          parent: 'La caractéristique est un parent et ne peut pas avoir son propre parent.',
          fail: 'La caractéristique n\'a pas pu être modifiée.',
          exist: 'La caractéristique existe déjà.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la caractéristique.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la récupération des données de la caractéristique.'

        },
        ui: {
          form: {
            fields: {
              name: {
                label: 'Label'
              },
              parent: {
                name: 'Caractéristique parent',
                hint: 'Une caractéristique parent ne peut pas avoir son propre parent.'
              }
            }
          }
        }
      }
    }
  },
  site: {
    name: 'Sites',
    products: {
      name: 'Produits'
    },
    catalogs: {
      name: 'Catalogues'
    },
    solutions: {
      name: 'Solutions'
    },
    pages: {
      name: 'Pages'
    },
    promos: {
      name: 'Promotions'
    },
    configurators: {
      name: 'Configurateur',
      list: {
        name: 'Configurateurs',
        notification: {
          site_not_found: 'Le site pour lequel vous souhaitez visualiser les configurateurs ne semble pas exister.',
          load: 'Impossible de récupérer la liste des configurateurs. Veuillez rafraîchir la page.'
        },
        ui: {
          title: 'Configurateurs',
          subtitle: 'Retrouvez ci-dessous la liste des configurateurs.',
          datatable: {
            os: 'OS',
            education: 'Établissement',
            usage: 'Type d\'usage',
            storage: 'Mobilier client',
            screen_size: 'Taille écran',
            device_nb: 'Nb postes',
            suitable_storage: 'Mobilier proposé'
          }
        }
      },
      add: {
        title: 'Ajout',
        name: 'Ajouter une nouvelle configuration',
        notification: {
          added: 'La configuration à bien été ajoutée.',
          site_not_exist: 'Le site pour lequel vous souhaitez rajouter une configuration ne semble pas exister.',
          product_not_exist: 'Certains des produits que vous souhaitez rajouter à la configuration ne semble pas exister.',
          exist: 'La configuration existe déjà.',
          fail: 'La configuration n\'a pas pu être ajoutée. Certains paramètres sont invalides.',
          fail_fetch: 'Une erreur se produite pendant le chargement des étapes. Veuillez rafraîchir la page. ',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la configuration.'
        },
        ui: {
          title: 'Ajouter une nouvelle configuration',
          subtitle_main: 'Informations principales',
          subtitle_secondary: 'Affiner configuration',
          form: {
            fields: {
              education: 'Sélectionner le niveau scolaire:',
              usage: 'Sélectionner le type d\'usage:',
              os_type: 'Sélectionner le système d\'exploitation:',
              storage: 'Sélectionner le mobilier:',
              devices: 'Sélectionner les produits à proposer:',
              screen_size: {
                title: 'Renseigner la taille de l\'ordinateur:',
                size_min: {
                  name: 'Taille min',
                  hint: 'Taille min de l\'ordinateur, en pouces'
                },
                size_max: {
                  name: 'Taille max',
                  hint: 'Taille max de l\'ordinateur, en pouces'
                },
                rules: {
                  number: 'La taille doit être un nombre valide',
                  not_null: 'La taille est obligatoire',
                  not_zero: 'La taille doit être supérieure à zéro',
                  min_max: 'La taille min doit être inférieure à la taille max',
                  max_min: 'La taille max doit être supérieure à la taille min'
                }
              },
              device_nb: {
                title: 'Renseigner le nombre des postes:',
                nb_min: 'Minimum',
                nb_max: 'Maximum',
                rules: {
                  number: 'Le nombre des postes doit être un nombre valide',
                  not_null: 'Obligatoire',
                  not_zero: 'Le nombre des postes doit être supérieur à zéro',
                  min_max: 'Le nombre des postes min doit être inférieur au nombre des postes max',
                  max_min: 'Le nombre des postes max doit être supérieur au nombre des postes min'
                }
              },
              storage_precise: 'Proposer un mobilier adéquat:'
            },
            rules: {
              product: 'Au moins un produit est nécessaire pour créer une configuration.'
            }
          }
        }
      },
      edit: {
        title: 'Edition',
        name: 'Éditer la configuration',
        notification: {
          edited: 'La configuration à bien été éditée.',
          site_not_exist: 'Le site pour lequel vous souhaitez éditer la configuration ne semble pas exister.',
          product_not_exist: 'Certains des produits que vous souhaitez rajouter à la configuration ne semble pas exister.',
          not_exist: 'La configuration que souhaitez éditer ne semble pas exister.',
          exist: 'La configuration existe déjà.',
          fail: 'La configuration n\'a pas pu être éditée. Certains paramètres sont invalides.',
          fail_fetch: 'Une erreur se produite pendant le chargement des étapes. Veuillez rafraîchir la page. ',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la configuration.'
        },
        ui: {
          title: 'Éditer la configuration',
          subtitle_main: 'Informations principales',
          subtitle_secondary: 'Affiner configuration',
          form: {
            fields: {
              education: 'Sélectionner le niveau scolaire:',
              usage: 'Sélectionner le type d\'usage:',
              os_type: 'Sélectionner le système d\'exploitation:',
              storage: 'Sélectionner le mobilier:',
              devices: 'Sélectionner les produits à proposer:',
              screen_size: {
                title: 'Renseigner la taille de l\'ordinateur:',
                size_min: {
                  name: 'Taille min',
                  hint: 'Taille min de l\'ordinateur, en pouces'
                },
                size_max: {
                  name: 'Taille max',
                  hint: 'Taille max de l\'ordinateur, en pouces'
                },
                rules: {
                  number: 'La taille doit être un nombre valide',
                  not_null: 'La taille est obligatoire',
                  not_zero: 'La taille doit être supérieure à zéro',
                  min_max: 'La taille min doit être inférieure à la taille max',
                  max_min: 'La taille max doit être supérieure à la taille min'
                }
              },
              device_nb: {
                title: 'Renseigner le nombre des postes:',
                nb_min: 'Minimum',
                nb_max: 'Maximum',
                rules: {
                  number: 'Le nombre des postes doit être un nombre valide',
                  not_null: 'Obligatoire',
                  not_zero: 'Le nombre des postes doit être supérieur à zéro',
                  min_max: 'Le nombre des postes min doit être inférieur au nombre des postes max',
                  max_min: 'Le nombre des postes max doit être supérieur au nombre des postes min'
                }
              },
              storage_precise: 'Proposer un mobilier adéquat:'
            },
            rules: {
              product: 'Au moins un produit est nécessaire pour créer une configuration.'
            }
          }
        }
      },
      delete: {
        notification: {
          site_not_found: 'Le site pour lequel vous souhaitez supprimer le configurateur ne semble pas exister.',
          not_found: 'Le configurateur sélectionné ne semble pas exister.',
          fail: 'Impossible de supprimer le configurateur.',
          deleted: 'Le configurateur à bien été supprimé.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression du configurateur.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer ce configurateur ?'
        }
      }
    }
  },
  administration: {
    name: 'Administration',
    users: {
      name: 'Utilisateurs',
      see: {
        ui: {}
      },
      list: {
        name: 'Utilisateurs',
        notification: {
          load: 'Impossible de récupérer la liste des comptes utilisateur administrateur.'
        },
        ui: {
          title: 'Utilisateurs administrateur',
          subtitle: 'Retrouvez ci-dessous la liste des comptes utilisateur administrateur.',
          datatable: {
            sex: 'Civilité',
            lastname: 'Nom',
            firstname: 'Prénom',
            email: 'Email'
          }
        }
      },
      add: {
        name: 'Ajout',
        notification: {
          added: 'L\'utilisateur administrateur à bien été ajouter.',
          fail: 'L\'utilisateur administrateur n\'as pas pu être ajouter.',
          not_same_password: 'Les deux mots de passe ne sont pas identique.',
          email_exist: 'L\'adresse email est déjà utilisé par un autre utilisateur.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de l\'utilisateur administrateur.'
        },
        ui: {
          title: 'Ajout d\'un utilisateur administrateur',
          form: {
            passwords: {
              divider: 'Changement du mot de passe '
            },
            fields: {
              sex: {
                label: 'Civilité'
              },
              lastname: {
                label: 'Nom'
              },
              firstname: {
                label: 'Prénom'
              },
              email: {
                label: 'E-mail',
                rules: {
                  invalid: 'L\'adresse email n\'est pas valide.'
                }
              },
              password1: {
                label: 'Mot de passe',
                hint: '8 caractères minimum sont requis',
                rules: {
                  min: 'Le mot de passe doit comporter au moins 8 caractères'
                }
              },
              password2: {
                label: 'Confirmation du mot de passe',
                hint: '8 caractères minimum sont requis',
                rules: {
                  min: 'Le mot de passe doit comporter au moins 8 caractères'
                }
              }
            }
          },
          default: {
            name: '{ Nom de l\'utilisateur }'
          }
        }
      },
      edit: {
        name: 'Edition',
        notification: {
          edited: 'L\'utilisateur administrateur à bien été modifier.',
          update_himself_is_prohibited: 'Vous ne pouvez pas modifer votre compte utilisateur, préférer l\'interface profil pour mettre à jour vos informations.',
          not_find: 'L\'utilisateur administrateur ne semble pas exister.',
          fail: 'L\'utilisateur administrateur n\'as pas pu être modifier.',
          not_same_password: 'Les deux mots de passe ne sont pas identique.',
          email_exist: 'L\'adresse email est déjà utilisé par un autre utilisateur.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de l\'utilisateur administrateur.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la récupération des données de l\'utilisateur administrateur.'
        },
        ui: {
          title: 'Edition de l\'utilisateur administrateur',
          data: {},
          form: {
            passwords: {
              divider: 'Changement du mot de passe '
            },
            fields: {
              sex: {
                label: 'Civilité'
              },
              lastname: {
                label: 'Nom'
              },
              firstname: {
                label: 'Prénom'
              },
              email: {
                label: 'E-mail',
                rules: {
                  invalid: 'L\'adresse email n\'est pas valide.'
                }
              },
              password1: {
                label: 'Mot de passe',
                hint: '8 caractères minimum sont requis',
                rules: {
                  min: 'Le mot de passe doit comporter au moins 8 caractères'
                }
              },
              password2: {
                label: 'Confirmation du mot de passe',
                hint: '8 caractères minimum sont requis',
                rules: {
                  min: 'Le mot de passe doit comporter au moins 8 caractères'
                }
              }
            }
          },
          default: {
            name: '{ Nom de l\'utilisateur }'
          }
        }
      },
      delete: {
        notification: {
          not_found: 'Le compte administrateur client sélectionné ne semble pas exister.',
          fail: 'Impossible de supprimer le compte administrateur client.',
          deleted: 'L\'utilisateur client à bien été supprimer.',
          delete_himself: 'Vous ne pouvez pas supprimer votre propre compte utilisateur.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression du compte administrateur client.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer cette utilisateur ?'
        }
      }
    },
    vat: {
      name: 'TVA',
      see: {
        ui: {}
      },
      list: {
        name: 'TVA',
        notification: {
          load: 'Impossible de récupérer la liste des TVA enregistrées sur la plateforme.'
        },
        ui: {
          title: 'TVA',
          subtitle: 'Retrouvez ci-dessous la liste des TVA applicables.',
          datatable: {
            code: 'Code TVA',
            value: 'Valeur TVA'
          }
        }
      },
      edit: {
        name: 'Édition de la TVA',
        notification: {
          edited: 'La TVA à bien été modifiée.',
          not_find: 'La TVA ne semble pas exister.',
          fail: 'La TVA n\'a pas pu être modifiée.',
          vat_exist: 'La TVA existe déjà.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la TVA.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la récupération des données de la TVA.'
        },
        ui: {
          form: {
            fields: {
              code: {
                label: 'Code TVA'
              },
              value: {
                label: 'Valeur TVA',
                rules: {
                  value_number: 'La valeur doit être un nombre valide.'
                }
              }
            }
          }
        }
      },
      add: {
        name: 'Ajouter une nouvelle TVA',
        notification: {
          added: 'La TVA à bien été ajoutée.',
          fail: 'La TVA n\'a pas pu être ajoutée.',
          vat_exist: 'Une TVA avec les mêmes détails existe déjà.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la TVA.'
        },
        ui: {
          form: {
            fields: {
              code: {
                label: 'Code TVA'
              },
              value: {
                label: 'Valeur TVA',
                rules: {
                  value_number: 'La valeur doit être un nombre valide.'
                }
              }
            }
          }
        }
      },
      delete: {
        notification: {
          not_found: 'La TVA sélectionnée ne semble pas exister.',
          fail: 'Impossible de supprimer la TVA.',
          used: 'La TVA est utilisée et ne peut pas être supprimée.',
          deleted: 'La TVA à bien été supprimer.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression de la TVA.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer cette TVA ?'
        }
      }
    },
    setting: {
      name: 'Variables d\'environnement',
      list: {
        name: 'Variables d\'environnement',
        notification: {
          load: 'Impossible de récupérer la liste des variables d\'environnement enregistrées sur la plateforme.'
        },
        ui: {
          title: 'Variables d\'environnement',
          subtitle: 'Retrouvez ci-dessous la liste des variables d\'environnement.',
          datatable: {
            name: 'Nom',
            value: 'Valeur',
            description: 'Description'
          }
        }
      },
      edit: {
        name: 'Édition de la variable d\'environnement',
        notification: {
          edited: 'La variable d\'environnement à bien été modifiée.',
          not_find: 'La variable d\'environnement ne semble pas exister.',
          fail: 'La variable d\'environnement n\'a pas pu être modifiée.',
          vat_exist: 'La variable d\'environnement existe déjà.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la variable d\'environnement.',
          unknown_fetch: 'Une erreur inconnue s\'est produite lors de la récupération des données de la variable d\'environnement.'

        },
        ui: {
          form: {
            fields: {
              name: {
                label: 'Nom'
              },
              value: {
                label: 'Valeur'
              },
              description: {
                label: 'Description'
              }
            }
          }
        }
      },
      add: {
        name: 'Ajouter une nouvelle variable d\'environnement',
        notification: {
          added: 'La variable d\'environnement à bien été ajoutée.',
          fail: 'La variable d\'environnement n\'a pas pu être ajoutée.',
          unknown_save: 'Une erreur inconnue s\'est produite lors de la tentative d\'enregistrement des données de la variable d\'environnement.'
        },
        ui: {
          form: {
            fields: {
              name: {
                label: 'Nom'
              },
              value: {
                label: 'Valeur'
              },
              description: {
                label: 'Description'
              }
            }
          }
        }
      },
      delete: {
        notification: {
          not_found: 'La variable d\'environnement sélectionnée ne semble pas exister.',
          fail: 'Impossible de supprimer la variable d\'environnement.',
          deleted: 'La variable d\'environnement à bien été supprimer.',
          unknown: 'Une erreur inconnue s\'est produite lors de la tentative de suppression de la variable d\'environnement.'
        },
        ui: {
          title: 'Suppression',
          description: 'Voulez-vous vraiment supprimer cette variable d\'environnement ?'
        }
      }
    }
  }
}
