<template>
  <v-card class="flex d-flex flex-column" style="align-items: stretch; align-content: stretch; align-self: stretch">
    <div>
      <v-checkbox class="select_checkbox"
                  color="success"
                  hide-details
                  v-model="isSelected"
                  @click="selectProduct"
                  :disabled="loading_from_parent ? loading_from_parent : product.disabled"
      ></v-checkbox>
      <v-img
        class="white--text align-end"
        :src="product.image"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
        contain

      >
      </v-img>
    </div>
    <v-card-title>{{ product.label }}</v-card-title>
    <v-card-text class="flex">
      <v-row
        align="center"
        class="mx-0"
      >
        <p> Reference : {{ product.reference }}</p>
      </v-row>
      <div class="my-4 subtitle-1">
        Prix : {{ helpers.toEuro(product.price_taxless) }}
      </div>
      <div class="h2 text-left" v-if="!_.isEmpty(product.description_short)"> {{ product.description_short }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProductComponent',
  props: {
    product: {
      required: true
    },
    loading_from_parent: {
      type: Boolean
    }
  },
  data: () => ({
    disabled: false
  }),
  created () {
  },
  mounted () {
  },
  methods: {
    selectProduct () {
      this.$emit('update-selected', {
        add: !this.isSelected,
        id: this.product.id
      })
    }
  },
  watch: {},
  computed: {
    isSelected: {
      get: function () {
        const selected = this.product.selected
        return selected
      },
      set: function (newValue) {
        const selected = this.product.selected
        return selected
      }
    }
  }
}
</script>

<style scoped>

.h2 {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  -webkit-line-clamp: 3;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  margin: 20px 0;
}

.select_checkbox {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

</style>
