<template>
  <v-row style="width: 100%">
    <v-col cols="12" sm="4" class="pt-8">
      <v-autocomplete
        v-model="selected_file_type"
        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.files.file_type')"
        clearable
        dense
        small-chips
        deletable-chips
        :items="items"
        :item-text="item_text"
        :item-value="item_value"
        hide-no-data
        hide-selected
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6">
      <v-file-input
        :rules="rules"
        v-model="file"
        accept=".pdf"
        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.files.file')"
      ></v-file-input>
    </v-col>
    <v-col cols="1" sm="1" class="text-center align-self-center">
      <v-btn
        color="success"
        fab
        x-small
        dark
        @click="add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="1" sm="1" class="text-center align-self-center">
      <v-btn
        color="error"
        fab
        x-small
        dark
        @click="remove"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FileUpload.vue',
  props: {
    items: {
      required: true
    },
    item_text: {
      required: true
    },
    item_value: {
      required: true
    },
    index: {
      required: true
    },
    rules: {
      required: true
    }
  },
  data: function () {
    return {
      selected_file_type: null,
      file: null
    }
  },
  methods: {
    updateInput () {
      this.$emit('input')
    },
    remove () {
      this.$emit('remove', this.index)
    },
    add () {
      this.$emit('add')
    }
  }
}
</script>

<style scoped>

</style>
