/**
 * Manage app sessions using vue-session
 */

import Vue from 'vue'
import VueSession from 'vue-session'

Vue.use(VueSession, { persist: true })
window.$session = Vue.prototype.$session

export default window.$session
